<template>
	<div class="wrapper">
		<div class="container-fluid">
			<!-- Page-Title -->
			<div class="row">
				<div class="col-sm-12">
					<div class="page-title-box">
						<h3 class="page-title">Home</h3>
					</div>
				</div>
			</div>
			<!-- end page title end breadcrumb -->
			<div class="img-home">
				<img :src="image" class="responsive">
			</div>
		</div>
		<!-- End container-fluid -->
		<Footer />
	</div>
</template>

<script>
import bgHome from "@/assets/images/bg-home.png";
import Footer from '@/views/layouts/FooterComponent.vue';
export default {
	name: 'Home',
	components: {
		Footer
	},
	data() {
		return {
			image: bgHome
		}
	}
}
</script>

<style lang="css" scoped>
.responsive {
	max-width: 100%;
	height: auto;
	position: absolute;
	right: 0px;
	bottom: 70px;
}
</style>